import { useEffect, useState } from "react"
import { vars } from "../utils/constants"
import { apiValidateCoupon } from "../apis/coupons"
import { apiCreaterazorpayPaymentOrder, apiRazorpayOrderFailed, apiRazorpayOrderSuccess } from "../apis/payments"
import { getLoggedInUserEmail, getLoggedInUserName } from "../utils/utils"


function CourseSide({data, setShowAuthModal, courseId, coupon}){

    console.log( 'CourseSide: ', data)

    const [couponData, setCouponData] = useState({coupon:'', apiMessage:''})
    const [couponErrors, setCouponErrors] = useState({coupon:'', apiError:''})
    const [sellingData, setSellingData] = useState({originalPrice: data.l_course_price, sellingPrice:data.l_course_price, is_coupon_applied:false, couponId: '', couponCode: ''    })
    const [isApiInProgress, setApiInProgress] = useState(false)
    const [showCouponForm, setShowCouponForm] = useState(false)
    

    const handleApplyCoupon = async () =>{
        try{
            let errorMessages = {...couponErrors}
            let errors = 0;
            if( couponData.coupon.length > 3 ){
              errorMessages = {...errorMessages, coupon: ''}
            }else{
              errors +=1
              errorMessages = {...errorMessages, coupon: 'Min. 4 characters'}
            }
            setCouponErrors({...errorMessages})
            if( errors == 0 && isApiInProgress == false){
                setApiInProgress(true)
                let response = await apiValidateCoupon({...couponData})
                if(response.data.result == vars.SUCCESS){
                    setCouponErrors({...couponErrors, apiError: ''})
                    setCouponData({...couponData, apiMessage: response.data.message})
                    calculateCoupon(response.data.data.coupon)
                }else{
                    setCouponErrors({...couponErrors, apiError: response.data.message})
                }
                setApiInProgress(false)
            }
        }catch(ex){
            setCouponErrors({...couponErrors, apiError: ex.message})
            setApiInProgress(false)
        }

    }

    const calculateCoupon = (coupon) =>{
        // console.log(coupon)
        let sellingPrice = 0;
        if(coupon.type == vars.couponData.PRICE){
            sellingPrice = parseInt(data.l_course_price) - parseInt(coupon.value)
        }
        if(coupon.type == vars.couponData.PERCENTAGE){
            let subAmount = parseInt(data.l_course_price) * parseInt(coupon.value)/100
            sellingPrice = parseInt(data.l_course_price) - subAmount
        }
        // console.log(sellingPrice)
        setSellingData({...sellingData, sellingPrice: sellingPrice,is_coupon_applied: true, couponId: coupon.coupon_id, couponCode: coupon.code })
    }

    const [paymentData, setPaymentData] = useState({
        data:{
            apiMessage: ''
        },
        errors:{
            apiError:''
        }
    })

    const [showBuyModal, setShowBuyModal] = useState(false)

    /*
        1. add razorpay library
        2. Generate new order id
        3. Pass order id, amount to razorpay
        4. If success -> call order success api
        5. if failed -> call order failed api
    */
    
    useEffect( () =>{

        setCouponData({...coupon})
        calculateCoupon(coupon)
        // const script = document.createElement('script');
        // script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        // script.async = true;
        // document.body.appendChild(script);
        // return () => {
        //     document.body.removeChild(script);
        // };

    },[courseId])

    
    const handlePaymentBtn = async () =>{
        let apiResponse = await apiCreaterazorpayPaymentOrder({amount: 100});
        let orderId = apiResponse.data.data.order.id
        var options = {
            "key": "rzp_test_8gNHxGVGlM9lZR", // Enter the Key ID generated from the Dashboard
            "amount": 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            "currency": "INR",
            "order_id": orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            "handler": async function (response){
                // "paymentId": "", "orderId": "", "signature": ""
                console.log("Payment success")
                console.log(response.razorpay_payment_id);
                console.log(response.razorpay_order_id);
                console.log(response.razorpay_signature)
                let orderApiresponse = await apiRazorpayOrderSuccess({
                    paymentId: response.razorpay_payment_id, 
                    orderId: response.razorpay_order_id,
                    signature: response.razorpay_signature
                });
                console.log( orderApiresponse )
            },
            "prefill": { 
                "name": getLoggedInUserName(), 
                "email": getLoggedInUserEmail()
            }
        };
        console.log(options)
        var rzp1 = new window.Razorpay(options);
        rzp1.on('payment.failed', async function (response){
            // "paymentId": "", "orderId": "", "error": ""
            let orderFailedResponse = await apiRazorpayOrderFailed({
                paymentId: response.error.metadata.payment_id, 
                orderId: response.error.metadata.order_id,
                error: response.error
            });
            console.log( orderFailedResponse )
            console.log("Payment failed")
            console.log(response.error.code);
            console.log(response.error.description);
            console.log(response.error.source);
            console.log(response.error.step);
            console.log(response.error.reason);
            console.log(response.error.metadata.order_id);
            console.log(response.error.metadata.payment_id);
        });
        rzp1.open();
    }

    

    return(
            <div className="container content-space-t-3 content-space-t-md-4 content-space-t-lg-4 position-md-absolute top-0 start-0 end-0">
                <div className="row justify-content-end">
                    <div className="col-md-5 col-lg-4 position-relative zi-2 mb-7 mb-md-0">
                        <div id="stickyBlockStartPoint">
                            <div className="js-sticky-block" data-hs-sticky-block-options='{
                                "parentSelector": "#stickyBlockStartPoint",
                                "breakpoint": "md",
                                "startPoint": "#stickyBlockStartPoint",
                                "endPoint": "#stickyBlockEndPoint",
                                "stickyOffsetTop": 82,
                                "stickyOffsetBottom": 12
                            }'>
                                <div className="card">
                                    <div className="p-1">
                                        <div className="bg-img-start text-center rounded-2 py-10 px-5" style={{ backgroundImage: `url(${data.l_course_img_url})` }}>
                                            <a className="video-player video-player-btn" href="https://www.youtube.com/watch?v=d4eDWc8g0e0" role="button" data-fslightbox="youtube-video">
                                                <span className="d-flex justify-content-center align-items-center">
                                                    
                                                </span>
                                            </a>
                                        </div>
                                    </div>

                                    <div className="card-body">
                                        <div className="mb-3">
                                            <span className="card-title h2 final-amount">RS. {sellingData.sellingPrice.toLocaleString('en-US')}</span>
                                            &nbsp; &nbsp;
                                            <span className="text-muted">
                                                <del className="text-danger">
                                                    {
                                                        sellingData.couponCode.length > 0 &&
                                                        <span>
                                                            <del className='amount' >Rs. { parseInt(sellingData.originalPrice).toLocaleString('en-US')} </del>
                                                             
                                                        </span>
                                                    }
                                                </del>
                                                {
                                                    sellingData.couponCode.length > 0 && <span className="text-success"> &nbsp;20%</span>
                                                }
                                            </span>
                                            <div>
                                                {/* <div><div className="pointer text-primary font-size-80" onClick={e=> setShowCouponForm(!showCouponForm)} >Have a coupon?</div></div> */}
                                                {
                                                    showCouponForm && 
                                                    <div>
                                                        <div>
                                                            <input type="text" className=" form-control" placeholder="Coupon Code" onChange={e=>setCouponData({...couponData, coupon:e.target.value})} />
                                                            <span className="invalid-feedback demoMobile">{couponErrors.coupon}</span>
                                                        </div>
                                                        <button className="btn btn-primary" onClick={e=>handleApplyCoupon()}>Apply Coupon</button>
                                                        {
                                                            couponErrors.apiError.length > 0 && <div className="text-danger" role="alert">{couponErrors.apiError}</div>
                                                        }
                                                        {
                                                            couponData.apiMessage.length > 0 && <div className="text-success" role="alert">{couponData.apiMessage}</div>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                        <div className="d-grid mb-2">
                                            <button className="btn btn-warning btn-transition" onClick={ e => setShowBuyModal(true) } >Buy now</button>
                                        </div>
                                        <div>
                                        {
                                            paymentData.errors.apiError.length > 0 && <div className="text-danger" role="alert">{paymentData.errors.apiError}</div>
                                        }
                                        </div>

                                        <div className="text-center mb-4">
                                            <p className="card-text small">7-day money-back guarantee</p>
                                        </div>
                                        <ul className="list-unstyled list-py-1">
                                            <li><i className="bi bi-caret-right-fill nav-icon"></i> Full time access</li>
                                        </ul>

                                        <h4 className="card-title">This course includes</h4>
                                        
                                        <ul className="list-unstyled list-py-1 ">
                                            {
                                                data['includes'].split("#").map( (inc, i) => (
                                                    <li key={i}><i className="bi bi-caret-right-fill nav-icon"></i> {inc}</li>
                                                ))
                                            }
                                        </ul>
                                        <div className="d-grid mb-2">
                                            <span> Talk to us, Please do call us on <a href="tel:9032029072" className="text-primary"> 9032029072 </a></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Payment modal */}
                {
                    showBuyModal && 
                    <div className="modal fade show" id="loginModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{display: 'block'}} >
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="card">
                                    <div id="forgotPassword"  data-target-group="idForm">
                            
                                        <header className="card-header bg-light py-3 px-5">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <h3 className="h6 mb-0">Buy Course</h3>
                                                <button type="button" className="btn-close"  onClick={e => setShowBuyModal(false) } ></button>
                                            </div>
                                        </header>
                                        <div className="card-body p-5">
                                            <div className="h6">
                                                <p>1. Scan and Pay <strong>RS. {sellingData.sellingPrice}</strong></p>
                                                <p>2. WhatsApp screenshot to 9032029072 along with your email</p>
                                                <p>3. We will enable the course and will send you login credentials</p>
                                            </div>
                                            <div className="text-center">
                                                <img src="https://d2b98ifobtd07j.cloudfront.net/payment/qrs/dd_upi.jpeg" alt="" className="card-img" style={{maxWidth: '250px'}} />
                                            </div>
                                            
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/* end payment modal */}
            </div>
    )

}

export default CourseSide
