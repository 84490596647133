import BookDemo from './App/BookDemo';
import Footer from './Common/Footer';
import Section4 from './App/Section4';
import StepsForJob from './App/StepsForJob';
import { useEffect, useState } from 'react';
import Offers from './Common/Offers';
import './header.css'


function App() {

  let [loginExpired, setLoginExpired] = useState(false)

  useEffect( () =>{
    const getExpiryData = () => {
      setLoginExpired(localStorage.getItem("login_expired") == undefined ? false: true)
      if (localStorage.getItem("login_expired") != undefined){
        localStorage.clear()
      }
      
    }

    getExpiryData()

  }, [])


  return (
    <div >
      
      <main id="content">
        <Offers />
        {
          loginExpired && 
          <div className="bg-light">
            
            <div className="container ">
                <div className="row justify-content-lg-between align-items-lg-center pt-5 ">
                    <div className="col-lg-12 mt-5 pt-5 ">
                    <div className='alert alert-danger'>Login expired. Please login again</div>
                    </div>
            </div>
            </div>
          </div>
        }
        
        <BookDemo />
        <StepsForJob />
        <Section4 />
        <Footer />
      </main>
    </div>
  );
}

export default App;
