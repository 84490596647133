function Course({data}){

    return(
        <div className="col-sm-6 col-lg-4 mb-4">
            <div className="card card-sm h-100 shadow-none  card-transition">
                <div className="p-2">
                    <a href={"learn/"+ data.l_course_id}>
                        <img className="card-img lazy" src={data.l_course_img_url} alt={data.l_course_name} />
                    </a>
                </div>
                <div className="card-body">
                    <h3 className="card-title">{data.l_course_name}</h3>
                    <div className="d-grid mt-3">
                        <a className="btn btn-soft-warning" href={"learn/"+data.l_course_id} >Learn</a>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Course;