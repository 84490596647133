import { useEffect, useState } from "react";

function Offers(){

    
    const calculateTimeLeft = () => {
        const difference = +new Date('2024-09-26') - +new Date();
        let timeLeft = {};
    
        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
                minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
                seconds: Math.floor((difference % (1000 * 60)) / 1000),
            };
        }
    
        return timeLeft;
    };
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft());
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    return(
            <div className="row mb-3">
                <div className="col-12">
                    
                    <div className="bg-ss text-center" >
                        
                        <div >
                            Vinayaka Chavithi Sale <strong className="h3  text-white text-highlight-warning">Price is Rs. 11,999</strong>
                        </div>
                        <div className="h2 mb-0 text-white">
                            Offer ends in {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m {timeLeft.seconds}s
                        </div>
                        {/* <div className="h2 mb-0 text-white">
                            Limited time offer, only available for a few days
                        </div> */}
                    </div>
                </div>
            </div>
            
    )
}

export default Offers;
